import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";
import * as b from "../components/bootstrap.module.css";
import Icon from "../components/icon.tsx";

const Breadcrumbs = ({ theme, items,textWhite }) => {
  if (!items) {
    return null;
  }

  if (theme === 'white') {
    return (
      <div>
        {items.map((item, i) => items.length !== (i + 1) ? (
          <Link to={item.url} key={item.url} className={['iconToGray','blackToGray'].join(' ')}>
            <span>{item.term}</span>
            <Icon color="#2B272F" size={20} icon="Angle-right" className={b.mx1} />
          </Link>
        ): (
          <span key={item.url} className='blackToWhite'>{item.term}</span>
        ))}
      </div>
    );
  }

  return (
    <div>
      {items.map((item, i) => items.length !== (i + 1) ? (
        <Link to={item.url} key={item.url} className={['iconToGray',textWhite ? 'breadcrumbs-lightBlue' : 'blackToGray'].join(' ')}>
          <span className={[b.fontWeightNormal].join(' ')}>{item.term}</span>
          {textWhite ? <Icon color="#B8D3FD" size={20} icon="Angle-right" className={b.mx1} />
          : <Icon color="#2B272F" size={20} icon="Angle-right" className={b.mx1} />}
        </Link>
      ): (
        textWhite ? <span key={item.url} className={b.textWhite}>{item.term}</span>
        : <span key={item.url} className="breadcrumbs-lastItem">{item.term}</span>
      ))}
    </div>
  );
};

Breadcrumbs.defaultProps = {
  textWhite: false,
}

Breadcrumbs.propTypes = {
  theme: PropTypes.string,
  textWhite: PropTypes.bool,
};

export default Breadcrumbs;
