import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Translate from "./translate";
import * as b from "../components/bootstrap.module.css";
import scrollTo from "gatsby-plugin-smoothscroll";
import { SCROLL_CLASS, DIRECTION_DOWN_CLASS, DIRECTION_UP_CLASS, } from "../utils/classes";
import useMedia from "../hooks/useMedia";
import Icon from "../components/icon.tsx";

let idTimeout = null;

const TableOfContents = ({ selector, isOnlyMobile, intermediary, children }) => {
  const isDesktopResolusion = useMedia(["(max-width: 1200px)"], [true], false);
  const [isOpen, setIsOpen] = useState(false);
  const [listItems, setListItems] = useState([]);

  const scroll = (id) => {
    clearTimeout(idTimeout);
    setIsOpen(false);
    document.body.classList.remove(DIRECTION_UP_CLASS);
    document.body.classList.add(SCROLL_CLASS, DIRECTION_DOWN_CLASS);
    scrollTo(`#${id}`);
    idTimeout = setTimeout(
      () => document.body.classList.remove(SCROLL_CLASS),
      2000
    );
  };

  useEffect(() => {
    if (!selector.length) {
      return;
    }

    const headers = [...document.querySelectorAll(selector)];

    for (let i = 0; i < headers.length; i++) {
      headers[i].setAttribute('id', `section-title-${i+1}`);
    }

    setListItems(
      headers.map(({ tagName, textContent, id }, i) => (
        <li key={i} className={`header-${tagName.toLowerCase().slice(-1)}`}>
          <button onClick={() => scroll(id)} className={["btn-hidden",'blackToWhite'].join(' ')}>
            {textContent}
          </button>
        </li>
      ))
    );
  }, [selector]);

  if (listItems.length <= 1) {
    return null;
  }

  return !isDesktopResolusion && !isOnlyMobile ? (
    <div className={intermediary ? b.colXl12 + b.px0 + ' toc-wrapper sticky ' : b.colXl4}>
      <div className={!intermediary ? ' toc-wrapper sticky ' + b.mb4 : ''}>
        <div className={["toc", b.card, "table-of-contents",b.p3,b.mt3].join(' ')}>
          <h4 className={["toc-title", b.mb0].join(' ')}><Translate tKey="Toc"/></h4>
          <ul className={['dark-theme-listing','blackToWhite', b.mt2].join(' ')}>{listItems}</ul>
        </div>
        {children}
      </div>
    </div>

) : (
  <div className={["mobile-toc", b.dXlNone, isOpen ? "is-open" : ""].join(" ")}>
    <button className={["mobile-toc-angles", "btn-hidden"].join(" ")} onClick={() => setIsOpen(!isOpen)}>
      <span className={[b.mr2,'blackToWhite',b.textCapitalize].join(' ')}>{isOpen ? <Translate tKey="hide" /> : <Translate tKey="Toc"/> }</span>
      {isOpen ? (
        <Icon key="1" size={40} icon="Angle-down" className='iconToWhite'/>
        ) : (
        <Icon key="2" size={40} icon="Angle-up" className='iconToWhite'/>
      )}
    </button>

    <div className={["mobile-toc-container", b.container].join(" ")}>
      <ul className={[b.my0, b.mb3,'dark-theme-listing'].join(" ")}>{listItems}</ul>
    </div>

    {children}
  </div>
  
);
};


TableOfContents.defaultProps = {
  intermediary: false,
}

TableOfContents.propTypes = {
  selector: PropTypes.string.isRequired,
  isOnlyMobile: PropTypes.bool,
  intermediary: PropTypes.bool,
};

export default TableOfContents;